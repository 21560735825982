<template>
  <v-app id="register-verify" class="security">
    <v-main>
      <v-container fluid fill-height>
        <v-row>
          <v-col cols="12">
            <v-form autocomplete="off" @submit.prevent="recover">
              <v-card
                max-width="800"
                elevation="0"
                class="mx-auto py-0 mb-2"
                rounded="0"
              >
                <v-row no-gutters class="shadow-one">
                  <v-col
                    cols="12"
                    sm="6"
                    v-if="$vuetify.breakpoint.mdAndUp"
                    class="third d-flex"
                  >
                    <v-card
                      elevation="0"
                      class="py-0 mx-auto my-auto"
                      rounded="0"
                      max-width="400"
                      color="rgba(255,255,255,0)"
                    >
                      <v-img
                        width="230"
                        :src="require('@/assets/img/forgot-password.png')"
                      ></v-img>
                    </v-card>
                  </v-col>
                  <v-col
                    cols="12"
                    class="white"
                    :sm="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                  >
                    <v-card
                      elevation="0"
                      class="pt-4 mx-auto mb-5"
                      rounded="0"
                      max-width="400"
                    >
                      <v-card-text class="mb-0 pb-2 px-8 text-center">
                        <div class="d-flex justify-center">
                          <div class="mb-5">
                            <v-img
                              :src="require('@/assets/img/logo.png')"
                              width="60"
                            ></v-img>
                          </div>
                        </div>
                        <div class="text-h6 primary--text">
                          <span class="font-weight-bold">{{
                            $t("security.forgot_password.title")
                          }}</span>
                        </div>
                        <div class="text-body-2 grey--text">
                          {{ $t("security.forgot_password.description") }}
                        </div>
                      </v-card-text>
                      <v-card-text class="mt-2 mb-1 pb-0 px-8">
                        <div class="pb-4">
                          <span class="text-subtitle-2 font-weight-medium">{{
                            $t("security.forgot_password.help")
                          }}</span>
                        </div>
                        <v-text-field
                          append-icon="mdi-account-circle-outline"
                          name="email"
                          v-model="form.email"
                          :label="$t('security.fields.email.title')"
                          autocomplete="off"
                          outlined
                          dense
                          maxlength="100"
                          @input="delayTouch($v.form.email)"
                          @blur="$v.form.email.$touch"
                          :error-messages="emailErrors"
                          class="mb-1 font-weight-bold"
                        ></v-text-field>
                      </v-card-text>
                      <v-card-actions class="px-8 pt-0 mt-2">
                        <v-btn
                          color="primary"
                          block
                          type="submit"
                          :loading="loading"
                          :disabled="$v.form.email.$invalid"
                          >{{
                            $t("security.btn.forgot_password.submit")
                          }}</v-btn
                        >
                      </v-card-actions>
                      <v-card-text class="text-center py-0 my-2">
                        <router-link
                          class="font-weight-bold"
                          style="font-size: 1.05rem"
                          text
                          :to="$i18n.getRouteLink('login')"
                          >{{ $t("security.btn.login.title") }}</router-link
                        >
                      </v-card-text>
                      <v-card-actions class="mb-2 pb-0 mt-8 px-2">
                        <v-spacer></v-spacer>
                        <lang :btnOptions="{ 'x-small': true }"></lang>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
              <copyright></copyright>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Lang from './../commons/lang';
import { mapActions } from "vuex";
import { required, maxLength, email } from "vuelidate/lib/validators";
import FormDelayTouchMixin from "./../../mixins/form-delay-touch";
import Copyright from "./coypright";

const storageKeyEmailForgotPassword =
  process.env.VUE_APP_STORAGE_KEY_FORGOT_PASS || "_forgot_password_email";

export default {
  mixins: [FormDelayTouchMixin],
  data: () => ({
    form: {
      email: null,
    },
    loading: false,
  }),
  metaInfo() {
    return {
      title: this.$t("security.forgot_password.title"),
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
        maxLength: maxLength(100),
      },
    },
  },
  methods: {
    async recover() {
      let message = "security.fields.otp.invalid";

      this.notify({ status: false });

      this.$v.form.email.$touch();
      if (this.$v.form.email.$invalid) {
        this.notify({
          message: this.$t(message),
          centered: true,
          right: false,
        });
        return;
      }

      this.loading = true;
      try {
        await this.$recaptchaLoaded();
        const token = await this.$recaptcha("forgot_password");

        await this.request({
          url: "/api/auth/password-recovery",
          data: { email: this.form.email },
          method: "POST",
          headers: {
            recaptcha: token,
          },
          messages: {
            500: true,
            404: this.$t("security.error.email.not_found"),
            400: this.$t("error_codes.bad_request"),
          },
          notification: {
            right: false,
            centered: true,
          },
        });
        sessionStorage.setItem(storageKeyEmailForgotPassword, this.form.email);
        // redirect to password reset confirm
        this.$router.push(this.$i18n.getRouteLink("forgot-password-confirm"));
      } catch (error) {
        // eempty
      }
      this.loading = false;
    },
    ...mapActions({ notify: "notification/notify", request: "request" }),
  },
  computed: {
    emailErrors() {
      const errors = [];

      if (!this.$v.form.email.$dirty) return errors;

      !this.$v.form.email.required &&
        errors.push(this.$t("security.fields.email.required"));
      !this.$v.form.email.email &&
        errors.push(this.$t("security.fields.email.invalid"));
      !this.$v.form.email.maxLength &&
        errors.push(this.$t("security.fields.email.invalid"));
      return errors;
    },
  },
  components: {
    Copyright,
    Lang,
  },
};
</script>