<template>
  <forgot-password></forgot-password>
</template>

<script>
import Vue from 'vue';
import ForgotPassword from './../../components/security/forgot-password';
export default {
  beforeRouteEnter(to, from, next) {
    if (Vue.prototype.$auth.check()) {
      next({ name: "dashboard" });
    } else {
      next();
    }
  },
  data: () => ({}),
  components: {
    ForgotPassword
  }
}
</script>